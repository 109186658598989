import { types, flow } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
import Timestamp from './Timestamp';
import User from './User';
import SubLogItem from './SubscriptionLogItem';
import { firestore } from 'firebase/app';
import UserScheduleHistory from './UserScheduleHistory';
import moment from 'moment';

const db = firebase.firestore();

export default types
  .model('UserManager', {
    users: types.array(User),
    userScheduleHistory: types.optional(UserScheduleHistory, {}),
    userScheduleHistoryList: types.array(UserScheduleHistory),
  })
  .views((self) => ({
    get empty() {
      return self.users.length === 0;
    },
    get loading() {
      return !self.loaded;
    },
    get(id) {
      return self.users.find((user) => user.id === id);
    },
  }))
  .volatile((self) => ({
    loaded: false,
  }))
  .actions((self) => ({
    resetScheduleHistoryList(userId, littleOne) {
      self.userScheduleHistoryList = [];
      for (let i = 2; i >= 0; i--) {
        self.userScheduleHistoryList.push({
          currentDate: moment().subtract(i, 'days').toISOString().split('T').shift(),
          currentUserID: userId,
          currentLittleOneID: littleOne.id,
          currentScheduleHistory: {},
          ignoreTrackerEvents: [],
        });
      }
    },
    push() {
      self.users.push({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: {},
      });
      return self.users[self.users.length - 1];
    },
    // identifier could be email, UID, or Customer ID
    quickOpen: flow(function* (identifier) {
      try {
        identifier = identifier.trim();
        const existing = self.users.find((user) => {
          return user.id === identifier || user.email === identifier;
        });
        if (existing) {
          return existing;
        }
        const result = yield firebase.functions().httpsCallable('httpsUserGet')({
          emailOrUID: identifier,
        });
        if (!result.data) {
          throw Error(`Error getting the user information (${identifier})`);
        }

        self.users.push({
          ...result.data,
          shareTrackerEventsConsultants: result.data?.settings['share-tracker-events-consultants'] || false,
        });
        return self.users[self.users.length - 1];
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    loadOne: flow(function* (id) {
      self.loaded = false;
      self.unloadOne(id);
      const doc = yield db.collection('users').doc(id).get();
      self.users.push(
        Object.assign({}, doc.data(), {
          id: doc.id,
        })
      );
      yield new Promise((resolve) => setTimeout(resolve, 100));
      const user = self.users.find((u) => u.id === id);
      yield user.loadUserOwns();
      yield user.loadMemo();
      self.loaded = true;
      return user;
    }),
    unloadOne(id) {
      const existingIndex = self.users.findIndex((u) => u.id === id);
      if (existingIndex >= 0) {
        self.users.splice(existingIndex, 1);
      }
    },
    load: flow(function* (startAfter, limit) {
      self.loaded = false;
      limit = limit || 50;
      let query = db.collection('users').orderBy('created', 'desc');
      if (startAfter) {
        query = query.startAfter(startAfter);
      }
      const users = yield query.limit(limit).get();
      self.users = snapToArray(users);
      self.loaded = true;
    }),
    loadAdmins: flow(function* () {
      self.loaded = false;
      let query = db.collection('users').where('roles.admin', '==', true);
      const users = yield query.get();
      self.users = snapToArray(users);
      self.loaded = true;
    }),
    loadFiltered: flow(function* (filters, startAfter, limit) {
      self.loaded = false;
      limit = limit || 50;
      let query = db.collection('users');
      if (startAfter) {
        query = query.startAfter(startAfter);
      }
      if (filters.roles) {
        query = query.where(`roles.${filters.roles}`, '==', true);
      } else {
        if (filters.first_name) {
          query = query.where('first_name', '==', filters.first_name);
        }
        if (filters.last_name) {
          query = query.where('last_name', '==', filters.last_name);
        }
        if (filters.created_range) {
          let range = filters.created_range;
          range[1] = new Date(range[1].toISOString());
          range[1].setHours(23);
          range[1].setMinutes(59);
          range[1].setSeconds(59);
          query = query.where('created', '>', range[0]);
          query = query.where('created', '<', range[1]);
        }
      }
      const users = yield query.limit(limit).get();
      self.users = snapToArray(users);
      self.loaded = true;
    }),
    loadByIds: flow(function* (ids) {
      if (!ids || ids.length === 0) return;
      self.loaded = false;

      const result = [];
      const limit = 10;
      for (let i = 0; i < Math.ceil(ids.length / 10); i++) {
        const from = i * limit;
        const to = from + limit;
        const segmentedIds = ids.slice(from, to);
        const userDocs = yield db.collection('users').where(firestore.FieldPath.documentId(), 'in', segmentedIds).get();
        result.push(snapToArray(userDocs));
      }

      self.users.push(...result.flat());
      self.loaded = true;
    }),
    async getSubLogs(userID) {
      const result = await db.collection('subscription_logs').where('uid', '==', userID).get();
      const arr = snapToArray(result);
      arr.sort((a, b) => {
        return a.time.seconds - b.time.seconds;
      });
      return arr.map((log) => SubLogItem.create(log));
    },
    async getAllSubLogs({
      orderBy = 'time',
      order = 'desc',
      limit = 50,
      startAfter,
      platform,
      messageKeywords,
      startDate,
      endDate,
    }) {
      try {
        const subsLogs = [];

        const fetchSubLogs = async (startAfter) => {
          let docRef = db.collection('subscription_logs').orderBy(orderBy, order).limit(limit);
          if (startAfter) {
            docRef = docRef.startAfter(startAfter);
          }
          if (startDate) {
            docRef = docRef.where('time', '>=', firestore.Timestamp.fromDate(startDate));
          }
          if (endDate) {
            docRef = docRef.where('time', '<=', firestore.Timestamp.fromDate(endDate));
          }

          const result = await docRef.get();
          let lastDoc = result.docs.length > 0 ? result.docs[result.docs.length - 1] : null;

          subsLogs.push(
            ...snapToArray(result)
              .map((log) => SubLogItem.create(log))
              .filter((log) => {
                if (platform && platform !== log.platform) return false;
                if (messageKeywords && !log.msg.toLowerCase().includes(messageKeywords.toLowerCase())) return false;
                return true;
              })
          );

          if (result.docs.length >= limit && subsLogs.length < limit) {
            const nextRows = await fetchSubLogs(lastDoc);
            lastDoc = nextRows.lastDoc;
          }

          return Promise.resolve({ subsLogs, lastDoc });
        };

        const result = await fetchSubLogs(startAfter);
        return Promise.resolve(result);
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    },
    async getUserOwns(userID) {
      const result = await db.collection('user_owns').doc(userID).get();
      return Object.assign({}, result.data(), { id: result.id });
    },
    async getIPs(userID) {
      const result = await db.collection('users').doc(userID).collection('ips').get();
      return result.docs.slice(0).map((snap) => ({
        address: snap.id,
        city: snap.get('city') || '',
        country: snap.get('country') || '',
        date: Timestamp.create(snap.get('date')),
      }));
    },
    async getForeignIDs(userID) {
      const result = await db.collection('fid_to_uid').where('uid', '==', userID).get();
      const arr = snapToArray(result);
      arr.sort((a, b) => {
        return a.created.seconds - b.created.seconds;
      });
      return arr;
    },
    async getReferredBy(userID) {
      const result = await db.collection('invitations').where('claimedBy', 'array-contains', userID).get();
      if (result.docs.length > 0) {
        let doc = result.docs[0];
        return doc.get('from');
      } else {
        return null;
      }
    },
    async getInvitations(userID) {
      const result = await db.collection('invitations').where('from.uid', '==', userID).get();
      if (result.docs.length > 0) {
        let arr = snapToArray(result);
        return arr;
      } else {
        return [];
      }
    },
    // identifier could be email, or platform ID
    async getShopifyInfo(identifier) {
      try {
        identifier = identifier.trim();
        let result = await db.collection('shopify_customers').doc(identifier).get();
        if (result.exists) {
          return result.data();
        }

        // use email if there's no platformID
        result = await db.collection('shopify_customers').where('email', '==', identifier).get();
        if (result.empty || !result.docs[0].exists) {
          throw new Error('Data did not exist');
        }
        return result.docs[0].data();
      } catch (err) {
        return Promise.reject(err);
      }
    },
  }));
